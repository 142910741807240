<template>
    <div id="UserGroup">
        <v-container fluid app>
            <ZnapTable ref="znapTable"
                :endpoint="endpoint"
                :tableName="tableName"
                :tableIcon="tableIcon"
                :tableDescription="tableDescription"
                :options="options"
                :tableRows="tableRows"
                :noData="noData"
                @setNoData="setNoData"
                :noDataMessage="noDataMessage"
                @setNoDataMessage="setNoDataMessage"
                :filterConditions="filterConditions"
                :checkboxFilters="checkboxFilters"
                @setFilterConditions="setFilterConditions"
                @clearFilters="setFilterConditions"
                :loadTable="loadTable"
                @setLoadTable="setLoadTable"
                @clearNotRequiredOptionsItems="clearNotRequiredOptionsItems"
            >
                <!-- // Filters -->
                <template v-slot:filters>
                    <ZnapFilters ref="znapFilters"
                        :filterComponents="options"
                        :noData="noData"
                        @setSelection="populateFilterConditions"
                        :clearFilters="clearFilters"
                        :setSelectedFilter="setSelectedFilter"
                        @setClearFilters="setClearFilters"
                        @setCheckboxValue="setCheckboxValue($event)"
                    >
                    </ZnapFilters>
                </template>
            </ZnapTable>
        </v-container>
    </div>
</template>

<script>
import Vue from 'vue'
import ZnapTable from '@/components/znap/ZnapTable.vue'
import ZnapFilters from '@/components/znap/Filters.vue'

export default {
    name:'UserGroup',

    components: { ZnapTable, ZnapFilters },

    computed: {
        endpoint() {
            return [ this.$ipSecurity, 'user-group' ]
        },
    },

    data: () => ({
        tableName: 'Perfil do usuário',
        tableIcon: 'mdi-account-group',
        tableDescription: 'Forneça uma descrição para esta tabela.',
        tableRows: 10000,

        options: [
            {
                column: 'id_user',
                is: 'ComboBox',
                items: [],
                multiple: false,
                label: 'Usuário',
                required: false,
                filterable: false,
                endpoint: [ Vue.prototype.$ipUser, 'user' ]
            },
            {
                column: 'id_group',
                is: 'ComboBox',
                items: [],
                multiple: false,
                label: 'Grupo',
                required: false,
                filterable: false,
                endpoint: [ Vue.prototype.$ipSecurity, 'group' ]
            },
            {
                column: 'tag',
                is: 'Checkbox',
                value: false,
                label: 'Ocultar grupos de relatórios',
                required: false,
                inConditions: true,
            },
        ],

        noDataMessage: '',
        noData: true,

        // Filters
        filterConditions: [],
        checkboxFilters: [],
        loadTable: false,
        clearFilters: false,
        setSelectedFilter: null
    }),

    created() {
        if (this.$route.params.id){
            let item = this.$route.params.id
            this.setSelectedFilter = { column: 'id_group', value: item }
            console.log(this.setSelectedFilter)
        }

        let checkboxOptions = this.options.filter(o => o.is === 'Checkbox')
        checkboxOptions.forEach(c => {
            this.checkboxFilters.push({ column: c.column, value: c.value })
        })
    },

    methods: {
        setCheckboxValue(e) {
            let checkbox = this.checkboxFilters.find(c => c.column === e.column)
            checkbox.value = e.value
        },
        
        populateFilterConditions(selectionArray, requiredFilter) {
            this.filterConditions = []

            if (requiredFilter) {
                this.filterConditions.push({
                    AndOr: 'AND',
                    column: selectionArray[0].column,
                    operator: '=',
                    value: selectionArray[0].id,
                    required: true
                })

                this.loadTable = true
            } else {
                if (selectionArray.length !== 0) {
                    selectionArray.forEach(selection => {
                        
                        let required = false
                        this.options.forEach(option => {    
                            if (option.required && selection.column === option.column) {
                                required = true
                            }
                        })
                        console.log(selection.id)
                        if (selection.id === undefined) {
                            this.filterConditions = []
                        } else {
                            if (selection.id.length > 1) {
                                this.filterConditions.push({
                                    AndOr: 'AND',
                                    column: selection.column,
                                    operator: 'IN',
                                    value: selection.id,
                                    required
                                })
                            } else {
                                this.filterConditions.push({
                                    AndOr: 'AND',
                                    column: selection.column,
                                    operator: '=',
                                    value: selection.id,
                                    required
                                })
                            }             
                        }
                    })
                }
            }

            if (this.setSelectedFilter) {
                this.loadTable = true
                this.setSelectedFilter = null
            }
        },

        setNoDataMessage(message) {
            this.noDataMessage = message
        },

        setNoData(payload) {
            this.noData = payload
        },

        setFilterConditions(array) {
            this.filterConditions = array
            
            if (array.length === 0) {
                this.clearFilters = true
            }

            this.options.forEach(option => {
                if (option.required) {
                    this.loadTable = true
                } 
            })
        },

        setClearFilters(payload) {
            this.clearFilters = payload
        },

        clearNotRequiredOptionsItems() {
            this.options.forEach(option => {
                if (!option.required) {
                    option.items = []
                } 
            })
        },

        setLoadTable(payload) {
            this.loadTable = payload
        }
    },
}
</script>

<style>
</style>